@import "breakpoints";

.select-form {
  border-radius: 10px;
  background: #FFFFFF !important;
  box-shadow: 0 2px 22px #CCDAE7 !important;

  .item {
    cursor: pointer;
    transition: opacity .2s ease-in-out;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: -0.04em;
    color: #6B89A4;

    &:hover {
      opacity: .8;
    }
  }

  .mat-mdc-menu-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

}

.filter-form {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  .filter-fields {
    width: 100%;
    display: flex;
    column-gap: 1rem;

    .input-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      row-gap: .5rem;
    }
  }

}

.mat-mdc-menu-panel {
  max-width: none !important;
  min-width: unset !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.cdk-overlay-pane:has(.todo) {
  border-radius: 1rem;
  box-shadow: 0 .2rem 2.2rem 0 #CCDAE7;
  overflow: hidden;

  .todo {
    .mat-mdc-menu-content {
      padding: 0;
      margin: 0;
      min-width: 36rem;
      max-width: 36rem;
    }

    @include screen('md') {
      max-width: 100%;
    }
  }
}

.mobile-group {
  & + * .cdk-overlay-pane:has(.buttons-group) {
    border-radius: 1rem;
    box-shadow: 0 .2rem 2.2rem 0 #CCDAE7;
    overflow: hidden;

    .buttons-group {
      .mat-mdc-menu-content {
        padding: 0;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem
      }

      @include screen('md') {
        max-width: 100%;
      }
    }
  }
}

.cdk-overlay-pane:has(.reactions-menu) {
  transform: translate(0, calc(-100% - 3.5rem));
}


