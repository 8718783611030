@import "breakpoints";

@mixin content {
  .mat-mdc-dialog-content {
    max-height: 100%;
    overflow: inherit;
  }
}

@mixin surface {
  .mat-mdc-dialog-surface {
    overflow-y: visible !important;
  }
}

@mixin padding {
  .mat-mdc-dialog-content {
    padding: 0;
  }
}

.chat-modal {
  mat-dialog-container {
    @include content;
    @include surface;
    @include padding;
  }
}

.create-modal {
  mat-dialog-container {
    @include content;
    @include surface;
  }
}

.cdk-overlay-pane {
  @include screen('md') {
    max-width: 90vw !important;
  }
}


