@use '@angular/material' as mat;
@import "src/styles/palettes";

$blue: mat.get-color-from-palette($luxcore-palette, 700);

.ck-balloon-panel {
  z-index: 100000 !important;
}

.ck-powered-by-balloon {
  display: none !important;
}

.mention-item {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  &.ck-on {
    background-color: #E3EDF7 !important;
  }

  .user-name {
    color: $blue !important;
    font-size: 1.4rem;
    letter-spacing: 0.25px;
  }

  .user-avatar {
    object-fit: cover;
    border-radius: 50%;
  }
}

.ck-mentions {
  min-width: 25rem;
}

//
//.ck-body:has(.ck-powered-by) {
//  .ck-powered-by {
//    display: none !important;
//  }
//}

