:root {
    --glow-color: #3072bd;
}

@keyframes mat-spinner {
    to {transform: rotate(360deg);}
}

.mat-spinner::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: mat-spinner .8s linear infinite;
}

.hide-btn-text {
    color: transparent !important;
}

.mat-glow {
    animation: glow 1s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 3px -3px var(--glow-color);
    }
    to {
        box-shadow: 0 0 3px 3px var(--glow-color);
    }
}