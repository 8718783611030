@use '@angular/material' as mat;
@import "src/styles/palettes";

.error-snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background: mat.get-color-from-palette($crimson-palette, 400) !important;

    .mat-mdc-button {
      color: white !important;
    }
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: white;
    font-family: var(--medium);
    white-space: pre-wrap
  }
}
