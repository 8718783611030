@import "mat-dialog-overrides";
@import "mat-button";
@import "absence-hint";
@import "menu-overrides";
@import "pagination";
@import "snack-bar";
@import "actions-menu";

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
