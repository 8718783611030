.generic-timepicker {
  .ngx-timepicker-control__arrows {
    display: none !important;
  }

  .ngx-timepicker__time-colon {
    margin: 0 !important;
  }

  .ngx-timepicker {
    border: 0 !important;
  }

  .ngx-timepicker-control {
    width: 2.4rem !important;
    padding: 0 !important;
  }

  .ngx-timepicker-control--active:after {
    display: none !important;
  }

  .ngx-timepicker-control--active {
    border: 0 !important;
  }

  .ngx-timepicker__toggle {
    order: 0 !important;

    > button {
      padding: 0;
      margin-right: 3px;

      span {
        height: 24px;
      }
    }
  }

  .ngx-timepicker-control__input {
    font-size: 1.6rem !important;
    font-family: var(--regular) !important;
    background-color: transparent;
    padding: 0 !important;
  }
}
