@use '@angular/material' as mat;
@import "src/styles/palettes";

$blue: mat.get-color-from-palette($luxcore-palette, 700);

.core-button {
  font-family: var(--bold) !important;
  font-size: 1.4rem !important;
  line-height: 100% !important;
  letter-spacing: -0.04em !important;

  .mat-mdc-button-touch-target {
    height: 100% !important;
  }
}

.core-button mat-icon {
  font-size: 1.4rem !important;
  font-weight: bold;
  line-height: 100% !important;
  letter-spacing: -0.04em !important;
}

.mdc-button.mat-light-gray,
.mat-button.mat-light-gray,
.mdc-fab.mat-light-gray,
.mat-raised-button.mat-light-gray,
.mat-stroked-button.mat-light-gray {
  border-radius: 7px !important;
  border: none !important;
  background: #E3EDF7 !important;
  box-shadow: none !important;
  color: #6B89A4 !important;
}

.mdc-button.mat-light-blue,
.mat-button.mat-light-blue,
.mdc-fab.mat-light-blue,
.mat-raised-button.mat-light-blue,
.mat-stroked-button.mat-light-blue {
  border-radius: 10px !important;
  border: 1px solid #E3EDF7 !important;
  background: #ECF4FF !important;
  box-shadow: none !important;
  color: $blue !important;
  min-height: 4rem !important;
}

//mat-icons
.mat-icon.mat-gray {
  color: #6B89A4;
  line-height: 100%;
}

//mat-icons
.mat-icon.mat-light-gray {
  color: #E3EDF7;
  line-height: 100%;
  transition: color .2s ease-in-out;

  &:hover {
    color: #6B89A4;
  }
}

