@import "src/app/modules/reminder/add-reminder-modal/generic-pickers/generic-timepicker.overrides";

.timepicker-overlay {
  z-index: 99999 !important;
}

.timepicker-backdrop-overlay {
  z-index: 99999 !important;
}

.shifts-timepicker {
  .ngx-timepicker-control__input {
    font-size: 1.4rem !important;
    font-family: var(--regular) !important;
  }
}

ngx-material-timepicker-content {
  --dial-background-color: #284DD8 !important;
  --clock-hand-color: #284DD8 !important;
  --clock-face-background-color: #E3EDF7 !important;
  --button-color: #284DD8 !important;
}
