@use '@angular/material' as mat;

@import "styles/material-ui-overrides/overrides";
@import "styles/ckeditor/ckeditor";
@import "styles/fonts";
@import "styles/mixins/breakpoints";
@import "styles/material-ui-overrides/timepicker";

/**ToolTip**/
@import "tippy.js/dist/tippy.css";

/** Themes */
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/themes/translucent.css';

/** Animations */
@import 'tippy.js/dist/backdrop.css';
@import 'tippy.js/animations/perspective.css';
@import 'tippy.js/animations/perspective-extreme.css';
@import 'tippy.js/animations/perspective-subtle.css';
@import 'tippy.js/animations/scale.css';
@import 'tippy.js/animations/scale-extreme.css';
@import 'tippy.js/animations/scale-subtle.css';
@import 'tippy.js/animations/shift-away.css';
@import 'tippy.js/animations/shift-away-extreme.css';
@import 'tippy.js/animations/shift-away-subtle.css';
@import 'tippy.js/animations/shift-toward.css';
@import 'tippy.js/animations/shift-toward-extreme.css';
@import 'tippy.js/animations/shift-toward-subtle.css';

/** Arrow */
@import 'tippy.js/dist/svg-arrow.css';

@font-face {
  font-family: 'SF Bold';
  src: url('./assets/fonts/SF-C/SFCompactText-Bold.eot');
  src: url('./assets/fonts/SF-C/SFCompactText-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SF-C/SFCompactText-Bold.woff2') format('woff2'),
  url('./assets/fonts/SF-C/SFCompactText-Bold.woff') format('woff'),
  url('./assets/fonts/SF-C/SFCompactText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Light';
  src: url('./assets/fonts/SF-C/SFCompactText-Light.eot');
  src: url('./assets/fonts/SF-C/SFCompactText-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SF-C/SFCompactText-Light.woff2') format('woff2'),
  url('./assets/fonts/SF-C/SFCompactText-Light.woff') format('woff'),
  url('./assets/fonts/SF-C/SFCompactText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFCompactText';
  src: url('./assets/fonts/SF-C/SFCompactText-Medium.eot');
  src: url('./assets/fonts/SF-C/SFCompactText-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SF-C/SFCompactText-Medium.woff2') format('woff2'),
  url('./assets/fonts/SF-C/SFCompactText-Medium.woff') format('woff'),
  url('./assets/fonts/SF-C/SFCompactText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Semibold';
  src: url('./assets/fonts/SF-C/SFCompactText-Semibold.eot');
  src: url('./assets/fonts/SF-C/SFCompactText-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SF-C/SFCompactText-Semibold.woff2') format('woff2'),
  url('./assets/fonts/SF-C/SFCompactText-Semibold.woff') format('woff'),
  url('./assets/fonts/SF-C/SFCompactText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Regular';
  src: url('./assets/fonts/SF-C/SFCompactText-Regular.eot');
  src: url('./assets/fonts/SF-C/SFCompactText-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SF-C/SFCompactText-Regular.woff2') format('woff2'),
  url('./assets/fonts/SF-C/SFCompactText-Regular.woff') format('woff'),
  url('./assets/fonts/SF-C/SFCompactText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


:root {
  --regular: 'SF Regular', sans-serif;
  --medium: 'SF Semibold', sans-serif;
  --bold: 'SF Bold', sans-serif;
  --light: 'SF Light', sans-serif;

  --glow-color: #3072bd;
}

* {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}


a {
  color: inherit;
  text-decoration: none;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
}

html, body {
  $letter-spacing: -0.56px;
  height: 100%;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  --mat-table-row-item-label-text-tracking: $letter-spacing;
  --mdc-typography-button-letter-spacing: $letter-spacing;
  --mdc-protected-button-label-text-tracking: $letter-spacing;
  --mdc-outlined-button-label-text-tracking: $letter-spacing;
  --mdc-filled-button-label-text-tracking: $letter-spacing;
  --mdc-dialog-supporting-text-tracking: $letter-spacing;
}

body {
  margin: 0;
  font-family: var(--regular);
  letter-spacing: -0.56px;
  text-rendering: geometricPrecision;
  font-size: 1.6rem;
}

.flex-spacer {
  flex-grow: 1;
}

.modal-backdrop {
  background-color: rgb(156 162 255 / 20%);
  backdrop-filter: blur(6px);
}

.header-button {
  background: mat.get-color-from-palette($luxcore-palette, A600) !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
  width: 3.6rem !important;
  height: 3.6rem !important;

  mat-icon {
    $size: 2rem;
    width: $size;
    height: $size;
    font-size: $size;
  }

}

@keyframes mat-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mat-spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: mat-spinner .8s linear infinite;
}

.hide-btn-text {
  color: transparent !important;
}

.mat-glow {
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px var(--glow-color);
  }
  to {
    box-shadow: 0 0 3px 3px var(--glow-color);
  }
}

grammarly-extension {
  display: none;
}

.dragging {
  background: white;
  z-index: 100;
}

//universal select dropdown menu global styles

.select-form-u {
  &__menu {
    top: 100%;
    left: 0;
    z-index: 100;

    width: 100%;
    max-height: 30rem;

    display: flex;
    flex-direction: column;

    background-color: mat.get-color-from-palette($custom-colors, white);
    border-radius: 6px;
    box-shadow: 0 .2rem 2.2rem 0 #CCDAE7;

    overflow-y: scroll;
    overflow-x: hidden;

    /* browser-specific scrollbar styles */
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: mat.get-color-from-palette($custom-colors, 'blue-gray') transparent; /* Firefox */

    &::-webkit-scrollbar { /* Chrome, Safari and Opera */
      width: .5rem;
    }

    &::-webkit-scrollbar-track { /* Chrome, Safari and Opera */
      background: transparent;
    }

    &::-webkit-scrollbar-thumb { /* Chrome, Safari and Opera */
      background: mat.get-color-from-palette($custom-colors, 'blue-gray');
    }
  }

  &__input {
    padding: 1rem;

    display: flex;
    align-items: center;
    gap: 1rem;

    background-color: mat.get-color-from-palette($custom-colors, 50);

    & :first-child {
      flex-grow: 1;
    }
  }

  &__options {
    list-style-type: none;
    margin: 0;
    padding: 0;

    width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__option {
    padding: 1rem 0;

    min-height: 3.8rem;
    border-top: 1px solid mat.get-color-from-palette($custom-colors, 100);

    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      padding: 0 2rem;
      width: 100%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.02);
      background-color: mat.get-color-from-palette($custom-colors, 50);
    }
  }
}

.disabled-select-option {
  cursor: not-allowed;
  opacity: .5;
}

