.mat-mdc-menu-panel {
  &.absence-menu {
    max-width: inherit !important;
    background: #FFFFFF !important;
    box-shadow: 0 2px 22px #CCDAE7 !important;
    border-radius: 1rem;

    .mat-mdc-menu-content {
      padding: 0 !important;
    }
  }
}
