$grid-breakpoints: (
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 900px,
  x: 1124px,
  xl: 1366px,
  xxl: 1680px
);

@mixin screen($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn 'Breakpoint `#{$breakpoint}` not found! Aviable #{map-keys($grid-breakpoints)}';
  }
}

@mixin hideOn($breakpoint) {
  @include screen($breakpoint) {
    display: none;
  }
}

@mixin showOn($breakpoint) {
  display: none;
  @include screen($breakpoint) {
    display: flex;
  }
}

@mixin generate-breakpoint-hide-classes {
  @each $breakpoint, $value in $grid-breakpoints {
    .hide-#{$breakpoint} {
      @media (max-width: $value - 1) {
        display: none !important;
      }
    }
  }
}

@mixin generate-breakpoint-show-classes {
  @each $breakpoint, $value in $grid-breakpoints {
    .show-#{$breakpoint} {
      @media (min-width: $value - 1) {
        display: none !important;
      }
    }
  }
}

@include generate-breakpoint-show-classes;
@include generate-breakpoint-hide-classes;
